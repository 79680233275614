// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

$MOBILE_MAX: 767px;
$TABLET_MIN: 768px;
$TABLET_MAX: 991px;
$DESKTOP_MIN: 992px;
$color_secundario: #1ab59f;
$color_secundario_hover: #1a9381;

img {
    max-width: 100%;
    height: auto;
}

.swal2-container {
    z-index: 100000 !important;
}

.display-none {
    display:none !important;
}

.cursorPointer {
    cursor:pointer !important;
}

.kt-width-full {
    width: 100%;
}

.col-flex {
    display: flex;

    &.justify-center {
        justify-content: center !important;
    }
    &.align-center {
        align-items: center;
    }

}

.botonIncidencia {
    background: #ff9510 !important;

    &:hover {
        background: #e08410 !important;
    }

}

.kt-portlet .kt-portlet__head {
    justify-content: flex-start;

}

.kt-portlet .kt-portlet__head .kt-portlet__head-label {
    flex: 1
}

.truncateEllipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.input-wrapper {
    position: relative;

    &:hover {
        & .botonRemoveValueInput {
            opacity: 1;
        }
    }

    & .botonRemoveValueInput {
        position: absolute;
        top: 26px;
        right: 14px;
        width: 16px;
        height: 16px;
        background: #efefef;
        border-radius: 60px;
        color: #3a394e;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        transition: all 0.2s;
        & svg {
            font-size: 10px;
        }
    }
}

.tab-app {

    padding: 24px;
}

.accionesGuardadoMobile {

    position: fixed;
    bottom: 0;
    background: #f8f9fa;
    left: 0;
    right: 0;
    height: 81px;
    z-index: 15555555500;
    border-top: 1px solid #dee2e6;
    & > div {
        height: 100%;
    }

}

.btn-mobile-fixed {
    width: 100%;
    height: 100%;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0.3px;
    transition: all 0.2s;
}

.btnSecundario {
    background-color: $color_secundario !important;
    color: white !important;
    &:active {
        background-color: $color_secundario_hover !important;
    }
}
.btnBlue {
    background-color: #404497 !important;
    color: white !important;
    &:active {
        background-color: #2b2e72 !important;
    }
}
.btnBlack {
    background-color: #212f3c !important;
    color: white !important;
    &:active {
        background-color: #131b22 !important;
    }
}

.btnSky {
    color: white !important;
    background-color: #4077df !important;
    &:active {
        background-color: #265cc1 !important;
    }
}

.btnRed {
    color: white !important;
    background-color: #fb3c6f;
    &:active {
        background-color: #e13563;
    }
}

.btnPurple {
    color: white !important;
    background-color: #843fde !important;
    &:active {
        background-color: #7124d6 !important;
    }
}

.accordion-small {
    min-height: 40px !important;
    & h5:first-of-type {
        font-size: 1.2rem !important;
    }
}

.contenedorTotalesEstadisticas {
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 1.2rem;
    margin-bottom: 15px;
    & > div {
        display: flex;
        gap: 5px;
        & > span:last-of-type {
            font-weight: 600;
        }
    }
}

.MuiOutlinedInput-root .MuiSelect-iconStandard {
    right: 7px;
}
.material-icons {
    font-size: 1.5rem;
}

.botonReactTableExcel {
    border: 0;
    background: none;
    margin: 0;
    padding: 0;
}

.MuiTablePagination-root p {
    margin: 0;
}

@media (max-width: $MOBILE_MAX) {
    .tab-app {
      padding: 3px;
    }

    .xs-hidden {
        display: none;
    }
}

@media (min-width: 600px) {
    .MuiTab-root {
        min-width: 160px;
    }
}
