.MuiInputBase-root {border:0px !important;}
.logoCliente{max-width: 400PX;}


@media (max-width: 600px) {
  .logoCliente {
    max-width: 300px;
  }



}